<template>


  <v-container fluid class="pa-0">
    <v-row justify="center" class=" pa-3" no-gutters>
      <v-col cols="12" md="6" sm="12">

        <transition name="scroll-right" appear>
          <h1 class="text-primary">AIA Health Saver ประกันสุขภาพเหมาจ่าย</h1>
        </transition>

        <p class="text-justify">
          มองหาประกันสุขภาพแบบเหมาจ่าย แนะนำ <b class="text-primary">AIA Health Saver</b> คุ้มครองค่ารักษาพยาบาล สูงสุด 500,000 บาท* พร้อมทั้งคุ้มครองค่ารักษาพยาบาล OPD สูงสุด 30 ครั้ง/ปีกรมธรรม์ 
        </p>
       <v-img src="aia-health-saver-page/img_1.jpg"/>
       <v-img src="aia-health-saver-page/img_2.jpg"/>
       <h3 class="text-primary">ข้อดีของประกันสุขภาพแบบเหมาจ่าย</h3>
       <v-list tag='ul'>
        <v-list-item tag='li'><b>- ความคุ้มครองครอบคลุม:</b> ประกันสุขภาพแบบเหมาจ่ายสามารถครอบคลุมค่าใช้จ่ายที่เกี่ยวข้องกับการรักษาพยาบาลได้อย่างครอบคลุม ไม่ว่าจะเป็นค่าห้องพัก ค่ายา ค่าผ่าตัด หรือค่าบริการอื่นๆ</v-list-item>
        <v-list-item tag='li'><b>- ความยืดหยุ่นในการเลือกโรงพยาบาล:</b> ผู้ถือกรมธรรม์สามารถเลือกโรงพยาบาลหรือสถานพยาบาลที่ต้องการได้ ไม่ว่าจะเป็นโรงพยาบาลรัฐหรือเอกชน</v-list-item>
          <v-list-item tag='li'><b>- การป้องกันความเสี่ยงทางการเงิน:</b> ในกรณีที่เกิดอุบัติเหตุหรือเจ็บป่วยที่ไม่คาดคิด ประกันสุขภาพจะช่วยลดภาระทางการเงินและป้องกันการเสียเงินจำนวนมากในคราวเดียว</v-list-item>
            <v-list-item tag='li'><b>- ความสบายใจและปลอดภัย:</b> เมื่อมีประกันสุขภาพแบบเหมาจ่าย ผู้ถือกรมธรรม์จะรู้สึกปลอดภัยและมั่นใจว่ามีการคุ้มครองที่เพียงพอในยามที่จำเป็น</v-list-item>
              <v-list-item tag='li'><b>- การสนับสนุนการดูแลสุขภาพ:</b> ประกันสุขภาพแบบเหมาจ่ายมักมีการส่งเสริมให้ผู้ถือกรมธรรม์ดูแลสุขภาพอย่างต่อเนื่อง เช่น การตรวจสุขภาพประจำปีหรือบริการให้คำปรึกษาทางการแพทย์</v-list-item>
                <v-list-item tag='li'><b>- การคุ้มครองระยะยาว:</b> ประกันสุขภาพแบบเหมาจ่ายมักมีระยะเวลาคุ้มครองมายาวนาน ช่วยให้คุณอุ่นใจในทุกช่วงชีวิต</v-list-item>
</v-list>
<h3 class="text-primary">ตารางความคุ้มครองของแผน AIA HEALTH SAVER</h3>
<v-img src="aia-health-saver-page/img_3.jpg"/>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col justify="center" class="text-center" cols="12"><v-btn color="primary"  @click="navigateToCalPremium">
          คำนวณเบี้ยประกัน
          คลิกเลย
        </v-btn></v-col>
    </v-row>

   
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
    {{ snackbarMessage }}
    <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
  </v-snackbar>
</template>

<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
import axios from '../plugins/axios'; // Import the custom axios instance
import logoPath from '../assets/logo.png';


export default {
  mounted() {
    document.title = "AIA Prime One"
    const metaTags = [
      { property: "og:title", content: "AIA Prime One" },
      { property: "og:description", content: "AIAPrimeONE เป็นตัวแทนประกันที่พร้อมจะดูแลและปกป้องคุณและครอบครัวในทุกสถานการณ์" },
      { property: "og:url", content: "https://aiaprimeone.com" },
      { property: "og:type", content: "website" }
    ];

    metaTags.forEach(tag => {
      const metaElement = document.createElement("meta");
      metaElement.setAttribute("property", tag.property);
      metaElement.setAttribute("content", tag.content);
      document.head.appendChild(metaElement);
    });
  },
  setup() {

    const drawer = ref(false);
    const snackbar = ref(false); // Controls the visibility of the snackbar
    const snackbarMessage = ref(''); // Holds the message to display in the snackbar
    const target = ref(null);
    const appBarHeight = 64;
    


  
    onMounted(() => {
    });
    onUnmounted(() => {
    });


    return {
      drawer,
      snackbar,
      snackbarMessage,
      appBarHeight
    };
  },
  computed: {
  },
  data() {
    return {
      logo: logoPath,
      // banners1: [
      //   {
      //     src: '/banner-master.jpg',
      //     // title: 'Welcome to our site',
      //     // description: 'This is the first banner description',
      //   },
      //   {
      //     src: '/banner-agent.jpg',
      //     // title: 'Discover our products',
      //     // description: 'This is the second banner description',
      //   },

      // ]

    };

  },

  methods: {
    navigateToCalPremium() {
      this.$router.push('/cal-premium'); // Ensure your router is set up for this path
    },
  }
};
</script>


<style scoped>
.v-card {
  margin-bottom: 1rem;
}

.header-cell {
  height: 200px;
  /* Set a fixed height for each header cell */
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  padding-top:5px !important;
  padding-bottom:5px !important;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Make header-content take the full height of the header cell */
  align-items: center;
  text-align: center;
}

.plan-button {
  margin-top: auto;
  /* Ensures the button sticks to the bottom */
  width: 100px;
  /* Optional: set a consistent width for the button */
  max-width: 100%;
}

.information_description {
  min-width: 150px;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 3s ease;
  /* Adjust speed and easing here */
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.scroll-right-enter-active {
  transition: all 0.6s ease-out;
}

.scroll-right-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.scroll-right-enter-from,
.scroll-right-leave-to {
  transform: translateX(40px);
  opacity: 0;
}
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
    transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.9);
}
</style>