<template>

  <v-row justify="center" class="mt-5 pt-5 bg-lightgray" v-intersect="wlHealth" no-gutters>

    <v-col cols="12" md="8" sm="12">
      <transition name="scroll-right">
        <h2 color="primary" v-if="showWlHealth" class="pl-3 text-primary">ประกันสุขภาพ</h2>
      </transition>
    </v-col>
  </v-row>
  <div class="bg-lightgray ma-0 pa-0">
    <transition name="fade">
      <WidgetBanner v-if="showWlHealth" :banners="aia_health" />
    </transition>
  </div>
  <v-row justify="center" class="pt-5 bg-lightgray" v-intersect="wlHB" no-gutters>
    <v-col cols="12" md="8" sm="12">
      <transition name="scroll-right">
        <h2 v-if="showWlHB" color="primary" class="pl-3 text-primary">ประกันชดเชยรายได้</h2>
      </transition>
    </v-col>
  </v-row>
  <div class="bg-lightgray ma-0 pa-0">
    <transition name="fade">
  <WidgetBanner v-if="showWlHB"  :banners="aia_hb" />
</transition>
</div>
  <v-row justify="center" class="pt-5 bg-lightgray" v-intersect="wlCancer" no-gutters>
    <v-col cols="12" md="8" sm="12">
      <transition name="scroll-right">
        <h2 color="primary" v-if="showWlCancer" class="pl-3 text-primary">ประกันโรคร้ายแรง</h2>
      </transition>
    </v-col>
  </v-row>
  <div class="bg-lightgray ma-0 pa-0">
    <transition name="fade">
  <WidgetBanner v-if="showWlCancer"  :banners="aia_cancer" />
</transition>
</div>

</template>

<script>
import WidgetBanner from './WidgetBanner.vue'; // Adjust the path as necessary

export default {
  components: {
    WidgetBanner,
  },
  methods: {
    wlHealth(isIntersecting) {
      if (isIntersecting && !this.hasShownWlHealth) {
        this.showWlHealth = true;
        this.hasShownWlHealth = true; // Prevents re-triggering
      }
    },
    wlHB(isIntersecting) {
      if (isIntersecting && !this.hasShownWlHB) {
        this.showWlHB = true;
        this.hasShownWlHB = true; // Prevents re-triggering
      }
    },
    wlCancer(isIntersecting) {
      if (isIntersecting && !this.hasShownWlCancer) {
        this.showWlCancer = true;
        this.hasShownWlCancer = true; // Prevents re-triggering
      }
    },
  },
  data() {
    return {
      showWlHealth: false,
      hasShownWlHealth: false, 
      showWlHB: false,
      hasShownWlHB: false,
      showWlCancer: false,
      hasShownWlCancer: false,
      aia_cancer: {
        care_for_cancer: {
          title: 'CARE FOR CANCER',
          hero_banner: {
            src: '/aia-cancer/aia-care-for-cancer-banner.avif',
            link: 'aia-care-for-cancer',
            title: 'สัญญาเพิ่มเติม เอไอเอ แคร์ ฟอร์ แคนเซอร์ (AIA Care for Cancer)',
            caption: 'รับเงินชดเชยรายวัน เมื่อเข้ารับการรักษาหรือตรวจโรคมะเร็งทุกระยะทั้งแบบ ผู้ป่วยในและผู้ป่วยนอก'

          },
          child_banner: [
          ]
        },
        ci: {
          title: 'CI',
          hero_banner: {
            src: '/aia-cancer/aia-ci-banner.avif',
            link: 'aia-ci',
            title: 'สัญญาเพิ่มเติมเอไอเอ ซีไอ - UDR',
            caption: 'หมดกังวลกับค่ารักษาพยาบาล รับเงินก้อนทันที 100 % เมื่อตรวจพบโรคร้ายแรงระยะรุนแรง'

          },
          child_banner: [
          ]
        },
        ci_pro_care: {
          title: 'CI PROCARE',
          hero_banner: {
            src: '/aia-cancer/aia-ci-procare-banner.avif',
            link: 'aia-ci-procare',
            title: 'เอไอเอ ซีไอ โปรแคร์',
            caption: 'จัดเต็มความคุ้มครองโรคร้าย ไม่จ่ายเบี้ยฯ ทิ้ง'

          },
          child_banner: [
          ]
        },
        ci_supercare_1099: {
          title: 'CI SUPERCARE 10/99',
          hero_banner: {
            src: '/aia-cancer/aia-ci-supercare-1099-banner.avif',
            link: 'aia-ci-supercare-1099',
            title: 'เอไอเอ ซีไอ ซูเปอร์แคร์ 10/99 (ไม่มีเงินปันผล)',
            caption: 'คุ้มครองโรคร้ายแรงนานตลอดชีพ'

          },
          child_banner: [
          ]
        },
        ci_supercare_2099: {
          title: 'CI SUPERCARE 20/99',
          hero_banner: {
            src: '/aia-cancer/aia-ci-supercare-2099-banner.avif',
            link: 'aia-ci-supercare-2099',
            title: 'เอไอเอ ซีไอ ซูเปอร์แคร์ 20/99 (ไม่มีเงินปันผล)',
            caption: 'คุ้มครองโรคร้ายแรงนานตลอดชีพ'

          },
          child_banner: [
          ]
        },
        ci_topup: {
          title: 'CI TOP UP',
          hero_banner: {
            src: '/aia-cancer/aia-ci-topup-banner.avif',
            link: 'aia-ci-topup',
            title: 'บันทึกสลักหลัง เอไอเอ ซีไอ ท็อปอัพ',
            caption: 'เพิ่มความอุ่นใจยิ่งขึ้น เติมเต็มความคุ้มครองโรคร้ายแรงครอบคลุมทุกระยะ'

          },
          child_banner: [
          ]
        },
        health_cancer: {
          title: 'HEALTH CANCER',
          hero_banner: {
            src: '/aia-cancer/aia-health-cancer-banner.avif',
            link: 'aia-health-cancer',
            title: 'สัญญาเพิ่มเติมผลประโยชน์มรณกรรมและการรักษาในโรงพยาบาลเนื่องจากโรคมะเร็ง',
            caption: 'รับค่าชดเชยรายวันหากต้องนอนโรงพยาบาล และรับเงินก้อนใหญ่กรณีเสียชีวิตเนื่องจากโรคร้ายแรง'

          },
          child_banner: [
          ]
        },
        lady_care_plus: {
          title: 'LADY CARE PLUS',
          hero_banner: {
            src: '/aia-cancer/aia-lady-care-plus-banner.avif',
            link: 'aia-lady-care-plus',
            title: 'สัญญาเพิ่มเติมผลประโยชน์เลดี้แคร์ พลัส',
            caption: 'เน้นคุ้มครองโรคมะเร็งในสตรีที่พบบ่อย และผู้ที่วางแผนตั้งครรภ์'

          },
          child_banner: [
          ]
        },
        multipay_ci_plus: {
          title: 'MULTIPAY CI PLUS',
          hero_banner: {
            src: '/aia-cancer/aia-multipay-ci-plus-banner.avif',
            link: 'aia-multipay-ci-plus',
            title: 'เอไอเอ มัลติเพย์ ซีไอ พลัส - UDR',
            caption: 'โรคร้าย เจอ จ่าย หลายจบ ครบถึงการดูแล'

          },
          child_banner: [
          ]
        },
        si_cancer: {
          title: 'SI CANCER',
          hero_banner: {
            src: '/aia-cancer/aia-si-cancer-banner.avif',
            link: 'aia-si-cancer',
            title: 'ประกันมะเร็ง ก่อนจะสาย',
            caption: 'อายุยังน้อย.. เหล้าไม่กิน บุหรี่ไม่สูบ ชะล่าใจไปหน่อย สุดท้ายมาเสียดายว่า รู้งี้..ทำประกันมะเร็งไว้ก็คงดี'

          },
          child_banner: [
          ]
        },
        term_ci: {
          title: 'TERM CI',
          hero_banner: {
            src: '/aia-cancer/aia-term-ci-banner.avif',
            link: 'aia-term-ci',
            title: 'ประกันโรคร้ายแรง อุ่นใจวัยเดอะ',
            caption: 'เริ่มเห็นคนใกล้ตัวเป็นโรคร้ายแรงกันมากขึ้น ถึงเวลาแล้วหรือยัง ที่จะมีประกันคุ้มครองทั้งชีวิต และโรคร้ายแรง'

          },
          child_banner: [
          ]
        },
        tpd: {
          title: 'TPD',
          hero_banner: {
            src: '/aia-cancer/aia-tpd-banner.avif',
            link: 'aia-tpd',
            title: 'สัญญาเพิ่มเติม เอไอเอ ผลประโยชน์กรณีทุพพลภาพถาวรสิ้นเชิง',
            caption: 'จ็บป่วย หรือบาดเจ็บ อนาคตคาดการณ์ไม่ได้ หากเกิดทุพพลภาพถาวรขึ้นมา ก็มั่นใจ มีเงินก้อนพร้อมดูแล'

          },
          child_banner: [
          ]
        },
        wcpi: {
          title: 'WCPI',
          hero_banner: {
            src: '/aia-cancer/aia-wcpi-banner.avif',
            link: 'aia-wcpi',
            title: 'สัญญาเพิ่มเติม เอไอเอ ผลประโยชน์ยกเว้นเบี้ยประกันภัยสำหรับเจ็บป่วยด้วยโรคร้ายแรง',
            caption: 'ให้คุณรับความคุ้มครองต่อเนื่อง หากเกิดเหตุการณ์ไม่คาดคิด'

          },
          child_banner: [
          ]
        },
      },
      aia_hb: {
        hb_care: {
          title: 'HB CARE',
          hero_banner: {
            src: '/aia-hb/aia-hb-care-banner.avif',
            link: 'aia-hb-care',
            title: 'ประกันชดเชยรายได้ เอไอเอ เอชบี แคร์ (AIA HB Care)',
            caption: 'แอดมิทสบายใจ รับเงินชดเชยรายได้สูงสุดวันละพัน'

          },
          child_banner: [
          ]
        },
        hb_extra: {
          title: 'HB EXTRA',
          hero_banner: {
            src: '/aia-hb/aia-hb-extra-banner.avif',
            link: 'aia-hb-extra',
            title: 'สัญญาเพิ่มเติม เอไอเอ เอชบี เอ็กซ์ตร้า',
            caption: 'ชดเชยรายได้ในวันที่ต้องนอนโรงพยาบาล ให้คุณมีเวลาดูแลตัวเองอย่างเต็มที่'

          },
          child_banner: [
          ]
        },
        health_cancer: {
          title: 'HEALTH CANCER',
          hero_banner: {
            src: '/aia-hb/aia-health-cancer-banner.avif',
            link: 'aia-health-cancer',
            title: 'สัญญาเพิ่มเติมผลประโยชน์มรณกรรมและการรักษาในโรงพยาบาลเนื่องจากโรคมะเร็ง',
            caption: 'รับค่าชดเชยรายวันหากต้องนอนโรงพยาบาล และรับเงินก้อนใหญ่กรณีเสียชีวิตเนื่องจากโรคร้ายแรง'

          },
          child_banner: [
          ]
        },
      },
      aia_health: {
        health_happy: {
          title: 'Health Happy',
          hero_banner: {
            src: '/aia-health/aia-health-happy-banner.avif',
            link: 'aia-health-happy',
            title: 'สัญญาเพิ่มเติมเอไอเอ เฮลธ์ แฮปปี้',
            caption: 'จะป่วยน้อยป่วยมาก...ก็หายห่วง คุ้มครองสูงสุด 25 ล้านบาท ต่อปี'

          },
          child_banner: [
          ]
        },
        health_saver: {
          title: 'Health Saver',
          hero_banner: {
            src: '/aia-health/aia-health-saver-banner.avif',
            link: 'aia-health-saver',
            title: 'สัญญาเพิ่มเติมเอไอเอ เฮลธ์ เซฟเวอร์',
            caption: 'คุ้มกว่าที่เคยเจอ เซฟเวอร์ทั่วไทย'
          },
          child_banner: [
          ]
        },
        health_happy_kids: {
          title: 'Health Happy Kids',
          hero_banner: {
            src: '/aia-health/aia-health-happy-kids-banner.avif',
            link: 'aia-health-saver',
            title: 'สัญญาเพิ่มเติมเอไอเอ เฮลธ์ แฮปปี้ คิดส์',
            caption: 'ยิ่งคิด(ส์) ยิ่งคุ้ม'
          },
          child_banner: [
          ]
        },
        health_plus: {
          title: 'Health Plus',
          hero_banner: {
            src: '/aia-health/aia-health-plus-banner.avif',
            link: 'aia-health-plus',
            title: 'สัญญาเพิ่มเติมเอไอเอ เฮลธ์ พลัส',
            caption: 'แบบประกันที่ตอบโจทย์มนุษย์เงินเดือน'
          },
          child_banner: [
          ]
        },
        hs_extra: {
          title: 'Health H&S Extra',
          hero_banner: {
            src: '/aia-health/aia-hs-extra-banner.avif',
            link: 'aia-hs-extra',
            title: 'สัญญาเพิ่มเติม เอไอเอ เอช แอนด์ เอส เอ็กซ์ตร้า',
            caption: 'เจ็บเล็กเจ็บน้อยก็หายห่วง คุ้มครองค่ารักษาพยาบาลทั้งผู้ป่วยในและผู้ป่วยนอก'
          },
          child_banner: [
          ]
        },
        hs: {
          title: 'Health H&S',
          hero_banner: {
            src: '/aia-health/aia-hs-newstd-banner.avif',
            link: 'aia-hs',
            title: 'สัญญาเพิ่มเติม เอไอเอ เอช แอนด์ เอส',
            caption: 'อุ่นใจเรื่องค่ารักษาพยาบาล บรรเทาภาระค่าใช้จ่ายจากการรักษาตัวในโรงพยาบาล'
          },
          child_banner: [
          ]
        },
        medcare_package: {
          title: 'Medcare Package',
          hero_banner: {
            src: '/aia-health/aia-med-care-banner.avif',
            link: 'aia-med-care',
            title: 'เอไอเอ เมดแคร์ แพ็กเกจ',
            caption: 'เจ็บป่วยแต่มีประกันสุขภาพก็เหมือนล้มบนฟูก'
          },
          child_banner: [
          ]
        },
        infinite_care: {
          title: 'Infinite Care',
          hero_banner: {
            src: '/aia-health/aia-infinite-care-banner.avif',
            link: 'aia-infinite-care',
            title: 'สัญญาเพิ่มเติม เอไอเอ อินฟินิท แคร์',
            caption: 'คุ้มครองสูง ครอบคลุมทั่วโลก ดูแลทุกระดับการรักษา'
          },
          child_banner: [
          ]
        }

      },
      banners: [
        {
          src: '/feed-banner/aia-health-happy1.jpg',
          link: '/aia-health-happy'
        },
        {
          src: '/feed-banner/aia-health-kids.jpg',
          link: '/aia-health-kids'
        },
        {
          src: '/feed-banner/aia-endowment-1525-1.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-day-surgery.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-discount.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare1.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-5reason.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare2.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare3.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare4.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare5.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protection-65.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-health-happy2.jpg',
          link: '/aia-health-happy'
        },
        {
          src: '/feed-banner/aia-infinite-care.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-health-saver.jpg',
          link: '/aia-health-saver'
        },
        {
          src: '/feed-banner/aia-health-saver2.jpg',
          link: '/aia-health-saver'
        },
        {
          src: '/feed-banner/aia-annuity-sure.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-3health-care.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-hand-foot-mouth-disease.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protectoin-65-2.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protectoin-65-3.jpg',
          link: '/'
        },
      ],
    };
  },
};
</script>
<style scoped>
.scroll-right-enter-active {
  transition: all 0.6s ease-out;
}

.scroll-right-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.scroll-right-enter-from,
.scroll-right-leave-to {
  transform: translateX(40px);
  opacity: 0;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>