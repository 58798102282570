// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import colors from 'vuetify/util/colors'

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  theme: {
    
    defaultTheme: 'light', // Enable light theme by default
    themes: {
      light: {
        dark: false,
          colors:{
          primary: '#d31145', // Set your primary color here
          secondary: '#fff',
          accent: colors.red.lighten5,
          error: '#dc3545',
          info: '#1f78ad',
          success: '#198754',
          warning: '#ffc107',
          lightgray: '#f7f7f8'
        }
      },
      dark: {
        colors:{
          primary: '#BB86FC', // Primary color for dark mode
          secondary: '#03DAC6',
          accent: '#03DAC6',
          error: '#CF6679',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      },
    },
    typography: {
      fontFamily: 'Noto Sans Thai, sans-serif',
    },
  }
})
