import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueGtag from 'vue-gtag-next';

console.log('Port:', process.env.VUE_APP_PORT);

loadFonts()

const app = createApp(App);

app.use(router); // Use the router in the app
app.use(vuetify); // Use Vuetify in the app
app.use(VueGtag, {property: { id: 'G-J2MYS3RVT5' }}),

app.mount('#app');