<template>
          <v-row no-gutters>
            <v-col cols="12" md="12" sm="12">
            
            <v-carousel
              hide-delimiters
              class="full-width-carousel"
              :style="{ height: carouselHeight }" ref="carouselRef"
              
            >
              <!-- Carousel Items (Add as many as you need) -->
              <v-carousel-item  height="auto" draggable="true" >
                <div class="video-overlay pa-0 ma-0">
                  
                   <div class="overlay-title pa-0 ma-0">
                  <h1 class=" text-white">AIA Prime One</h1>
                  <h2 class=" text-white">ให้เราได้ดูแลคุณ</h2>
                </div>
                <!-- <div class="dark-overlay pa-0 ma-0"> -->
                <video autoplay loop muted playsinline>
                  <source src="./hero-video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                </div>
              </v-carousel-item>
              <v-carousel-item
                v-for="(banner, index) in banners"
                :key="index"
                :src="banner.src"
                height="auto"
                draggable="true"
                
              >
        
              </v-carousel-item>

            </v-carousel>
          </v-col>
          </v-row>
        </template>
<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
export default {
  setup() {
    const carouselHeight = ref('auto');
    const carouselRef = ref(null);
    const isHeightSet = ref(false); // Flag to ensure height is only set once
    onMounted(() => {
      if (carouselRef.value && !isHeightSet.value) {
        console.log(isHeightSet.value)
        carouselHeight.value = `${carouselRef.value.clientHeight}px`;
        isHeightSet.value = true; // Set flag to true so height is not updated again
        console.log(isHeightSet.value)
      }
      console.log(isHeightSet.value)
    });

    return {
      banners: [
        {
          src: '/banner-agent.jpg',
          // title: 'Discover our products',
          // description: 'This is the second banner description',
        },
        {
          src: '/banner-master.jpg',
          // title: 'Welcome to our site',
          // description: 'This is the first banner description',
        },
      ],
      carouselHeight,
      carouselRef 
      
    }
    
  },
  // props: {
  //   banners: {
  //     type: Array,
  //     // Arrays and Objects must return factory functions instead of
  //     // literal values. You can't do `default: []`, you have to do
  //     // `default: function() { return []; }`... what I wrote was the 
  //     // short-hand for how to do it with ES6 fat-arrow functions
  //     default: () => ([]) 
  //   }
  // }
}

</script>
            
<style>

.video-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  margin:0;
  padding:0;
  object-fit: contain;
  display:block;
}

.overlay-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: white; */
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2); /* Adjust the opacity as needed */
  z-index: 1; /* Place behind the title text */
}
@media (max-width: 600px) {
  .overlay-title {
    font-size: 12px; /* Adjust to desired mobile size */
  }
}
.v-btn--variant-elevated, .v-btn--variant-flat{
  background: none !important; 
  color:rgb(var(--v-theme-secondary)) !important;
  box-shadow:none !important;
}
/* .full-width-carousel {
  aspect-ratio: 16 / 9 !important;
}
@media (max-width: 768px) {
  .full-width-carousel {
    aspect-ratio: 16 / 9 !important;
  }
} */
</style>