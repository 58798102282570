<template>
  <v-row justify="center" class=" pt-3 pb-3 mt-5"  no-gutters>
    <v-col cols="12" md="8" sm="12">
      <h2 class="text-primary text-center">แนะนำสำหรับคุณ</h2>
          <v-row no-gutters>
            <v-col v-for="(banner, index) in banners" cols="6" md="4"  :key="index" class="pa-2">
              <!-- <v-card  @click="$router.push({ path: banner.link })" elevation="16" >
                <v-img
                  :src="banner.src"
                ></v-img>
                <v-card-title>Nature Image</v-card-title>
                <v-card-subtitle>Beautiful view of nature</v-card-subtitle>
                <v-card-actions>
                  <v-btn color="primary" text>View</v-btn>
                  <v-btn color="secondary" text>Share</v-btn>
                </v-card-actions>
              </v-card> -->
              <v-card elevation="16" @click="openModal(banner.src)">
                <v-img :src="banner.src"></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Modal for Zoomed Image -->
   <!-- Modal for Zoomed Image -->
   <v-dialog v-model="isModalOpen" max-width="80%">
      <v-card>
        <!-- Close Button -->
        <v-btn
          icon
          class="close-btn"
          @click="isModalOpen = false"
          style="position: absolute; top: 10px; right: 10px; z-index: 100"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>

        <!-- Zoomed Image -->
        <v-img :src="currentImage" max-height="80vh" contain></v-img>
      </v-card>
    </v-dialog>
      </v-row>
</template>
<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
export default {
  setup() {
    // const carouselRef = ref(null);
    onMounted(() => {

    });

    // State for modal
    const isModalOpen = ref(false);
    const currentImage = ref("");

    const openModal = (src) => {
      currentImage.value = src;
      isModalOpen.value = true;
    };

    return {
      banners: [
        {
          src: '/feed-banner/aia-health-happy1.jpg',
          link: '/aia-health-happy'
        },
        {
          src: '/feed-banner/aia-endowment-1525-1.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-day-surgery.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-discount.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare1.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-5reason.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare2.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare3.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare4.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare5.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protection-65.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-health-happy2.jpg',
          link: '/aia-health-happy'
        },
        {
          src: '/feed-banner/aia-infinite-care.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-health-saver.jpg',
          link: '/aia-health-saver'
        },
        {
          src: '/feed-banner/aia-health-saver2.jpg',
          link: '/aia-health-saver'
        },
        {
          src: '/feed-banner/aia-annuity-sure.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-3health-care.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-hand-foot-mouth-disease.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protectoin-65-2.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protectoin-65-3.jpg',
          link: '/'
        },
      ],
      isModalOpen,
      currentImage,
      openModal,
      
    }
    
  },
  // props: {
  //   banners: {
  //     type: Array,
  //     // Arrays and Objects must return factory functions instead of
  //     // literal values. You can't do `default: []`, you have to do
  //     // `default: function() { return []; }`... what I wrote was the 
  //     // short-hand for how to do it with ES6 fat-arrow functions
  //     default: () => ([]) 
  //   }
  // }
}

</script>
            
<style scoped>
/* Remove white space in modal */

/* Styling for close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
  color: black;
}
</style>