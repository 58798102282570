// src/plugins/axios.js

import axios from 'axios';


// Create an instance of Axios with default settings
const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`, // Set your base URL here
  timeout: 30000, // Optional timeout in milliseconds
  changeOrigin: true,
});
console.log('API URL:', process.env.VUE_APP_API_URL);
// Optional: Add request/response interceptors
axiosInstance.interceptors.request.use(
  config => {
    // You can add headers or log requests here
    return config;
  },
  error => {
    // Handle the error
    return Promise.reject(error);
  }
);

// You can also add response interceptors if needed
axiosInstance.interceptors.response.use(
  response => {
    // Modify response if needed
    return response;
  },
  error => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
